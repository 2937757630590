/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
  isSingleSelect,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Editor from "../Layout/Editor";

var module = "clientpanel/holiday/";
var moduleName = "Holiday";
var modulePath = "/clientpanel/holiday";
class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/holiday/edit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      postdata: {
        outlets: { label: "All Outlet", value: "alloutlet" },
        title: "",
        description: "",
        holiday_date: "",
        status: [],
        action: "add",
      },
      loading: true,
      formpost: [],
      companyDetail: [],
      outletList: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.loadOutlet();
    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&detail_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          this.props.history.push(modulePath);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;

          var holiday_status =
            result.holiday_status == "A" ? "Active" : "In Active";
          var status =
            result.holiday_status !== "" && result.holiday_status !== null
              ? {
                  label: holiday_status,
                  value: result.holiday_status,
                }
              : "";
          var outlets =
            result.outlet !== "" && result.outlet !== null
              ? result.outlet
              : { label: "All Outlet", value: "alloutlet" };

          var clientupdatedata = {
            outlets: outlets,
            title: result.holiday_title,
            description:
              result.holiday_description !== "" &&
              result.holiday_description !== null
                ? result.holiday_description
                : "",
            holiday_date:
              result.holiday_date !== "" &&
              result.holiday_date !== null &&
              result.holiday_date !== "0000-00-00"
                ? new Date(result.holiday_date + " 00:00:00")
                : "",
            status: status,
            action: "edit",
          };
          this.setState({ postdata: clientupdatedata, pageloading: false });
        } else {
          this.setState({ pageloading: false });
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid" + this.state.moduleName, "error");
        }
      });
    }
  }
  loadOutlet() {
    var urlShringTxt =
      apiUrl + "clientpanel/outlets/dropdownlist?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;
        var outletsList = [{ label: "All Outlet", value: "alloutlet" }];
        if (result.length > 0) {
          result.map((item) => {
            outletsList.push(item);
          });
        }
        this.setState({ outletList: outletsList });
      }
    });
  }

  sateValChange = (field, value) => {};

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { postdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.postdata;

    var postObject = {
      outlet_id:
        Object.keys(postData.outlets).length > 0 ? postData.outlets.value : "",
      title: postData.title,
      holiday_date: postData.holiday_date,
      description: postData.description,
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"holiday"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>

                <PostForm
                  {...this.props}
                  fields={this.state.postdata}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  outletList={this.state.outletList}
                  error_msg={this.state.error_msg}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

function validationConfig(props) {
  const { title, holiday_date, description, status } = props.fields;

  return {
    fields: ["title", "holiday_date", "description", "status"],

    validations: {
      title: [[isEmpty, title]],
      holiday_date: [[isEmpty, holiday_date]],
      description: [[isEmpty, description]],
      status: [[isSingleSelect, status]],
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.setContent = this.setContent.bind(this);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }
  handleChangeDate(name, value) {
    this.props.onChange(name, value);
  }
  setContent(value) {
    this.props.onChange("description", value);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgTitle,
      errMsgHolidayDate,
      errMsgdescription,
      errMsgStatus = "";
    if ($validation.title.error.reason !== undefined) {
      errMsgTitle = $validation.title.show && (
        <span className="error">{$validation.title.error.reason}</span>
      );
    }
    if ($validation.holiday_date.error.reason !== undefined) {
      errMsgHolidayDate = $validation.holiday_date.show && (
        <span className="error">{$validation.holiday_date.error.reason}</span>
      );
    }
    if ($validation.description.error.reason !== undefined) {
      errMsgdescription = $validation.description.show && (
        <span className="error">{$validation.description.error.reason}</span>
      );
    }
    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="card-body row g-3">
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errMsgTitle !== "" &&
                  errMsgTitle !== false &&
                  errMsgTitle !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="title"
                value={fields.title}
                {...$field("title", (e) => onChange("title", e.target.value))}
              />
              <label htmlFor="title">
                Title <span className="error">*</span>
              </label>
              {errMsgTitle}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control"
                selected={fields.holiday_date}
                minDate={new Date()}
                dateFormat="d-MM-yyyy"
                placeholderText="Holiday Date"
                onChange={this.handleChangeDate.bind(this, "holiday_date")}
              />
              {errMsgHolidayDate}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.outlets}
                onChange={this.handleSelectChange.bind(this, "outlets")}
                placeholder="Select Outlet"
                options={this.props.outletList}
              />
              <label className="select-box-label">Outlet</label>
            </div>
          </div>

          <div className="col-md-12">
            <label>Short Description</label>
            <Editor setContent={this.setContent} data={fields.description} />
            {errMsgdescription}
          </div>
          <div
            className={
              errMsgStatus !== "" &&
              errMsgStatus !== false &&
              errMsgStatus !== undefined
                ? "col-md-6 error-select error mt-3"
                : "col-md-6 mt-3"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.status}
                onChange={this.handleSelectChange.bind(this, "status")}
                placeholder="Select Status"
                options={[
                  { value: "A", label: "Active" },
                  { value: "I", label: "In Active" },
                ]}
                isClearable={true}
              />
              <label className="select-box-label">
                Status<span className="error">*</span>
              </label>
              {errMsgStatus}
            </div>
          </div>

          <div className="pt-1 pb-4 pt-1 text-end">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
