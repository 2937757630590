import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import $ from "jquery";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
var module = "clientpanel/users/updatepassword/";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageloading: false,
      clientdata: {
        oldpassword: "",
        password: "",
        cpassword: "",
        action: "add",
      },
      loading: true,
      formpost: [],
      companyDetail: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
  }

  loadAvailabilty() {
    var urlShringTxt = apiUrl + "company/settings/availabilty_list";
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "success") {
        this.setState({ availabiltyList: res.data.result });
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "page") {
    }
  };

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.clientdata;

    var postObject = {
      oldpassword: postData.oldpassword,
      password: postData.password,
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };
    var post_url = module;

    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage="" />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">Change Password</h4>
                  </div>
                </div>
                <PostForm
                  {...this.props}
                  fields={this.state.clientdata}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);
const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";
function validationConfig(props) {
  const { oldpassword, password, cpassword } = props.fields;

  return {
    fields: ["oldpassword", "password", "cpassword"],

    validations: {
      oldpassword: [[isEmpty, oldpassword]],
      password: [[isEmpty, password]],
      cpassword: {
        rules: [
          [areSame, password, cpassword],
          [isEmpty, cpassword],
        ],
        fields: ["password", "cpassword"],
      },
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgOldPassword,
      errMsgPassword,
      errMsgConfirmPassword = "";
    if ($validation.oldpassword.error.reason !== undefined) {
      errMsgOldPassword = $validation.oldpassword.show && (
        <span className="error">{$validation.oldpassword.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }
    if ($validation.cpassword.error.reason !== undefined) {
      errMsgConfirmPassword = $validation.cpassword.show && (
        <span className="error">{$validation.cpassword.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="card-body row g-3">
          <div className="col-md-6">
            <div className="mb-4 form-password-toggle">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="password"
                    id="oldpassword"
                    className={
                      errMsgOldPassword !== "" &&
                      errMsgOldPassword !== false &&
                      errMsgOldPassword !== undefined
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={fields.oldpassword}
                    {...$field("oldpassword", (e) =>
                      onChange("oldpassword", e.target.value)
                    )}
                  />
                  <label htmlFor="oldpassword">
                    Old Password <span className="error">*</span>
                  </label>
                  {errMsgOldPassword}
                </div>
                <span
                  className="input-group-text rounded-end cursor-pointer"
                  id="basic-default-password2"
                  style={{ height: "49px" }}
                >
                  <i className="mdi mdi-eye-off-outline"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4 form-password-toggle">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="password"
                    id="password"
                    className={
                      errMsgPassword !== "" &&
                      errMsgPassword !== false &&
                      errMsgPassword !== undefined
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={fields.password}
                    {...$field("password", (e) =>
                      onChange("password", e.target.value)
                    )}
                  />
                  <label htmlFor="password">
                    Password <span className="error">*</span>
                  </label>
                  {errMsgPassword}
                </div>
                <span
                  className="input-group-text rounded-end cursor-pointer"
                  id="basic-default-password4"
                  style={{ height: "49px" }}
                >
                  <i className="mdi mdi-eye-off-outline"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4 form-password-toggle">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="password"
                    id="cpassword"
                    className={
                      errMsgConfirmPassword !== "" &&
                      errMsgConfirmPassword !== false &&
                      errMsgConfirmPassword !== undefined
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={fields.cpassword}
                    {...$field("cpassword", (e) =>
                      onChange("cpassword", e.target.value)
                    )}
                  />
                  <label htmlFor="cpassword">
                    Confirm Password <span className="error">*</span>
                  </label>
                  {errMsgConfirmPassword}
                </div>
                <span
                  className="input-group-text rounded-end cursor-pointer"
                  id="basic-default-password3"
                  style={{ height: "49px" }}
                >
                  <i className="mdi mdi-eye-off-outline"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="pt-1 pb-4 pt-1 text-end">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
