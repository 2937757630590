import React from "react";
import cookie from "react-cookies";
import { format } from "date-fns";
import Swal from "sweetalert2";
import noimage from "../../common/images/noimg-800x800.jpg";
import {
  deliveryId,
  apiUrl,
  masterheaderconfig,
  clientheaderconfig,
} from "./Config";
import $ from "jquery";
import Axios from "axios";
import parse from 'html-react-parser';

var qs = require("qs");
var base64 = require("base-64");

/* Random ID  */
export const randomId = function () {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

/*Reference ID Generate*/
export const getReferenceID = function () {
  if (typeof cookie.load("referenceId") === "undefined") {
    var randomKey = randomId();
    cookie.save("referenceId", randomKey, { path: "/" });
    return randomKey;
  } else {
    return cookie.load("referenceId");
  }
};

/* address format */
export const addressFormat = function (
  unitOne = '',
  unitTwo = '',
  addressOne = '',
  addressTwo = '',
  postCode = '',
  country = ""
) {
  let unit = '';
  if (unitOne) {
    unit += unitTwo ? `#${unitOne}-${unitTwo}, ` : `#${unitOne}, `;
  }

  if (addressOne) unit += `${addressOne}, `;
  if (addressTwo) unit += `${addressTwo}, `;
  if (postCode) unit += `<br/>${country} ${postCode}`;

  const formattedAddress = unit.replace(/,\s*$/, '');
  return formattedAddress ? parse(formattedAddress) : 'N/A';
};

/* show Alert */
export const showAlert = function (header, message, type, autoClose = "No") {
  if (autoClose === "No") {
    var icon = "";
    if (type === "success") {
      icon = "success";
    } else if (type === "success") {
      icon = "warning";
    } else {
      icon = "error";
    }
    Swal.fire({
      title: header,
      html: message,
      icon: icon,
      customClass: {
        confirmButton: "btn btn-primary waves-effect waves-light",
      },
      buttonsStyling: false,
    });
  } else {
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"></div></div></div></div></div></div>'
    );
    setTimeout(function () {
      autoClose.close();
    }, 1800);
  }
};

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append(
        '<div class="spinner-border loader-sub-div" role="status"><span class="visually-hidden">Loading...</span></div>'
      );
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append(
        '<div class="spinner-border loader-sub-div" role="status"><span class="visually-hidden">Loading...</span></div>'
      );
  }
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

/* sample funtion */
export const showPriceValue = function (price) {
  price = price !== "" && parseFloat(price) > 0 ? parseFloat(price) : 0.0;
  var priceTxt = "Rp " + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return priceTxt;
};

/* int to str funtion */
export const cnvrtStr = function (d) {
  return d < 10 ? "0" + d.toString() : d.toString();
};

export const showStatus = function (status) {
  if (status === "A") {
    return <span className="badge bg-label-success me-1">Active</span>;
  } else if (status === "I") {
    return <span className="badge bg-label-danger me-1">Inactive</span>;
  }
};

export const userID = function () {
  return cookie.load("loginID");
};
export const CompanyID = function () {
  return cookie.load("companyID");
};
export const clientID = function () {
  return cookie.load("clientID");
};

export const encodeValue = function (value) {
  if (value !== "") {
    return base64.encode(value);
  }
};
export const decodeValue = function (value) {
  if (value !== "") {
    return base64.decode(value);
  }
};

export const removeItem = function (
  formPayload,
  delUrl,
  type,
  sateValChange,
  currentPage
) {
  formPayload["company_admin_id"] = clientID();
  Swal.fire({
    title: "Are you sure?",
    text: "You want remove this item!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    customClass: {
      confirmButton: "btn btn-primary me-3 waves-effect waves-light",
      cancelButton: "btn btn-label-secondary waves-effect",
    },
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      var headerconfig =
        type === "client" ? clientheaderconfig : masterheaderconfig;

      return Axios.post(
        apiUrl + delUrl,
        qs.stringify(formPayload),
        headerconfig
      ).then((res) => {
        return res.data;
      });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.value !== "" && typeof result.value !== undefined) {
      if (result.value.status == "ok") {
        sateValChange("page", currentPage);
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: result.value.message,
          customClass: {
            confirmButton: "btn btn-success waves-effect",
          },
        });
      } else {
        Swal.fire({
          title: "Alert",
          text: result.value.message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger waves-effect",
          },
        });
      }
    }
  });
};
export const showDateTime = function (date) {
  if (date !== "" && typeof date !== undefined && typeof date !== "undefined") {
    return format(new Date(date), "MM/dd/yyyy hh:mm a");
  }
};
export const showDate = function (date) {
  if (date !== "" && typeof date !== undefined && typeof date !== "undefined") {
    return format(new Date(date), "MM/dd/yyyy");
  }
};

export const checkClientAuth = function (props) {
  if (
    cookie.load("clientID") === "" ||
    typeof cookie.load("clientID") === undefined ||
    typeof cookie.load("clientID") === "undefined"
  ) {
    props.history.push("/");
  }
};
export const checkMasterAuth = function (props) {
  if (
    cookie.load("loginID") === "" ||
    typeof cookie.load("loginID") === undefined ||
    typeof cookie.load("loginID") === "undefined"
  ) {
    props.history.push("/masterpanel");
  }
};
export const isEmpty = function (value) {
  return value === "" ? "This field is required." : null;
};
export const isEmail = function (value) {
  var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
  return value !== "" && value !== null && !pattern.test(value)
    ? "This is not a valid email."
    : null;
};
export const isPhone = function (value) {
  var filter = /^[0-9-+]+$/;
  return value !== "" && value !== null && !filter.test(value)
    ? "This is not a valid phone."
    : null;
};
export const isNumber = function (value) {
  var regex = /^[0-9\s]*$/;
  return value !== "" && value !== null && !regex.test(value)
    ? "This is not a valid number."
    : null;
};
export const isSingleSelect = function (value) {
  if (value !== null) {
    return Object.keys(value).length > 0 ? null : "This field is required.";
  } else {
    return "This field is required.";
  }
};
export const isMultiSelect = function (value) {
  return value.length > 0 ? null : "This field is required.";
};

export const isValidPrice = function (value) {
  var regex = /^\d{0,8}(\.\d{1,4})?$/;
  if (value !== "" && value !== null) {
  return value !== "" && !regex.test(value)
    ? "This is not a valid price."
    : null;
  } else {
    return null;
  }
};

export const checkingPermisson = function (currentMod, perType) {
  var premissons =
    cookie.load("userPremisson") !== "" &&
    typeof cookie.load("userPremisson") !== undefined &&
    typeof cookie.load("userPremisson") !== "undefined"
      ? cookie.load("userPremisson")
      : [];
  var loginType =
    cookie.load("clientLoginType") !== "" &&
    typeof cookie.load("clientLoginType") !== undefined &&
    typeof cookie.load("clientLoginType") !== "undefined"
      ? cookie.load("clientLoginType")
      : "";
  var availpermisson = 0;
  if (currentMod.length > 0) {
    currentMod.map((item) => {
      if (
        premissons[item] !== "" &&
        typeof premissons[item] !== undefined &&
        typeof premissons[item] !== "undefined" &&
        premissons[item].includes(perType)
      ) {
        availpermisson++;
      }
    });
  }
  if (availpermisson > 0 || loginType === "MainAdmin") {
    return true;
  } else {
    return false;
  }
};

export const uploadFile = async (files, folderName, type) => {
  try {
    if (files.size > 2 * 1024 * 1024) {
      return {
        status: "failed",
        msg: "File is too large to upload. Maximum allowed size is 2MB.",
      };
    }

    var headerconfig =
      type === "client" ? clientheaderconfig : masterheaderconfig;
    const formData = new FormData();
    formData.append("imageFile", files);
    formData.append("folderName", folderName);
    formData.append("logID", cookie.load("clientID"));
    const { data } = await Axios.post(
      apiUrl + "clientpanel/settings/uploadFile",
      formData,
      headerconfig
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const removeFile = async (files, type) => {
  try {
    var headerconfig =
      type === "client" ? clientheaderconfig : masterheaderconfig;
    const formData = new FormData();
    formData.append("fileName", files);
    formData.append("logID", cookie.load("clientID"));
    const { data } = await Axios.post(
      apiUrl + "clientpanel/settings/removeFile",
      formData,
      headerconfig
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const formatDateTime = async (date) => {
  if (!date) return "";

  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  const seconds = String(d.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function isEmptyData(value) {
	return (
		value === null || value === undefined || value === "undefined:undefined" || (typeof value === 'string' && value.trim() === '')
	);
}

export const convertStringTimeToDate = (timeString = "") => {

  if (isEmptyData(timeString)) return ""
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();

  // Set hours and minutes to the current date
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};