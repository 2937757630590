/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import {
  checkClientAuth,
  CompanyID,
  checkingPermisson,
} from "../../Helpers/SettingHelper";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import song from "../../../assets/audio/doordash.mp3";
import menuList from "./menu.json";
class Header extends Component {
  constructor(props) {
    super(props);
    checkClientAuth(this.props);

    this.state = {
      totalOrders: 0,
      todayOrders: 0,
      featureOrders: 0,
      loadInitital: true,
      audio: new Audio(song),
      userPremisson:
        cookie.load("userPremisson") !== "" &&
        typeof cookie.load("userPremisson") !== undefined &&
        typeof cookie.load("userPremisson") !== "undefined"
          ? cookie.load("userPremisson")
          : [],
      loginType:
        cookie.load("clientLoginType") !== "" &&
        typeof cookie.load("clientLoginType") !== undefined &&
        typeof cookie.load("clientLoginType") !== "undefined"
          ? cookie.load("clientLoginType")
          : "",
      // Set initial state of song
      isPlaying: false,
    };
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    this.loadActivity();
  }

  loadActivity() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/orders/getactivity?company_id=" +
      CompanyID() +
      "&logID=" +
      cookie.load("clientID");
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      var curretnThis = this;
      setTimeout(function () {
        //   curretnThis.loadActivity();
      }, 10000);
      if (res.data.status === "ok") {
        var totalOrders =
          parseInt(res.data.todayOrders) + parseInt(res.data.featureOrder);
        var isPlaying = this.state.isPlaying;
        if (
          this.state.totalOrders < totalOrders &&
          this.state.loadInitital === false
        ) {
          console.log("fdfsdfsfsfsf");
          isPlaying = true;
        }

        this.setState(
          {
            todayOrders: res.data.todayOrders,
            featureOrders: res.data.featureOrder,
            totalOrders: totalOrders,
            loadInitital: false,
          },
          function () {
            if (isPlaying === true) {
              $("#btn").trigger("click");
              //   $("#playaudio").trigger("click");
              // this.playPause();
            }
          }
        );
      } else {
        this.setState({
          todayOrders: 0,
          featureOrders: 0,
          totalOrders: 0,
        });
      }
    });
  }
  playPause() {
    const audioEl = document.getElementById("audio");
    console.log("triggerclick");
    var isPlaying = this.state.isPlaying;
    var currentThis = this;
    document.getElementById("btn").addEventListener("click", (event) => {
      audioEl.play();
      currentThis.setState({ isPlaying: true });
      /*  else {
        audioEl.pause();
        currentThis.setState({ isPlaying: false });
      } */
    });
    return false;

    // Get state of song
    /* let isPlaying = this.state.isPlaying;
    console.log("start", isPlaying);
    if (isPlaying) {
      // Pause the song if it is playing
      this.state.audio.pause();
    } else {
      // Play the song if it is paused
      this.state.audio.play();
    }

    // Change the state of song
    this.setState({ isPlaying: !isPlaying }); */
  }

  gotoLink(link, submenuLength, e) {
    e.preventDefault();
    if (submenuLength === 0) {
      this.props.history.push(`/clientpanel/${link}`);
    }
  }

  render() {
    var currentPage = this.props.currentPage;
    return (
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div style={{ display: "none" }}>
          <audio id="audio" controls src={song}></audio>
          <button id="btn" onClick={this.playPause.bind(this)}>
            {this.state.isPlaying === true ? (
              <span className="mdi mdi-stop"></span>
            ) : (
              <span className="mdi mdi-play"></span>
            )}
          </button>
        </div>

        <div className="app-brand demo">
          <Link to={"/clientpanel/dashboard"} className="app-brand-link">
            <span className="app-brand-logo demo">
              <span>
                <img src={"/logo.png"} alt="UFOOD" />
              </span>
            </span>
            <span className="app-brand-text demo menu-text fw-bold ms-2">
              UFOOD
            </span>
          </Link>

          <a
            href={void 0}
            className="layout-menu-toggle menu-link text-large ms-auto"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4854 4.88844C11.0081 4.41121 10.2344 4.41121 9.75715 4.88844L4.51028 10.1353C4.03297 10.6126 4.03297 11.3865 4.51028 11.8638L9.75715 17.1107C10.2344 17.5879 11.0081 17.5879 11.4854 17.1107C11.9626 16.6334 11.9626 15.8597 11.4854 15.3824L7.96672 11.8638C7.48942 11.3865 7.48942 10.6126 7.96672 10.1353L11.4854 6.61667C11.9626 6.13943 11.9626 5.36568 11.4854 4.88844Z"
                fill="currentColor"
                fillOpacity="0.6"
              />
              <path
                d="M15.8683 4.88844L10.6214 10.1353C10.1441 10.6126 10.1441 11.3865 10.6214 11.8638L15.8683 17.1107C16.3455 17.5879 17.1192 17.5879 17.5965 17.1107C18.0737 16.6334 18.0737 15.8597 17.5965 15.3824L14.0778 11.8638C13.6005 11.3865 13.6005 10.6126 14.0778 10.1353L17.5965 6.61667C18.0737 6.13943 18.0737 5.36568 17.5965 4.88844C17.1192 4.41121 16.3455 4.41121 15.8683 4.88844Z"
                fill="currentColor"
                fillOpacity="0.38"
              />
            </svg>
          </a>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          {menuList.map((item, index) => {
            if (checkingPermisson(item.moduleList, "view")) {
              return (
                <li
                  className={`menu-item ${
                    item.activeList.indexOf(currentPage) >= 0
                      ? item.subMenu.length > 0
                        ? "open"
                        : "active"
                      : ""
                  }`}
                  key={index}
                >
                  <a
                    href={`/clientpanel/${item.link}`}
                    className={`menu-link ${
                      item.subMenu.length > 0 && "menu-toggle"
                    }`}
                    onClick={this.gotoLink.bind(
                      this,
                      item.link,
                      item.subMenu.length
                    )}
                  >
                    <i className={`menu-icon tf-icons mdi ${item.icon}`}></i>
                    <div>{item.title}</div>
                    {item.key === "orders" && this.state.totalOrders > 0 && (
                      <div className="badge bg-primary rounded-pill ms-auto">
                        {this.state.totalOrders}
                      </div>
                    )}
                  </a>
                  {item.subMenu.length > 0 && (
                    <ul className="menu-sub">
                      {item.subMenu.map((subItem, subIndex) => {
                        if (checkingPermisson(subItem.moduleList, "view")) {
                          return (
                            <li
                              className={`menu-item ${
                                subItem.activeList.indexOf(currentPage) >= 0
                                  ? "active"
                                  : ""
                              }`}
                              key={subIndex}
                            >
                              <Link
                                to={`/clientpanel/${subItem.link}`}
                                className="menu-link"
                              >
                                <div>{subItem.title}</div>
                                {subItem.key === "feature-order" &&
                                  this.state.todayOrders > 0 && (
                                    <div className="badge bg-primary rounded-pill ms-auto">
                                      {this.state.todayOrders}
                                    </div>
                                  )}
                                {subItem.key === "feature-order" &&
                                  this.state.featureOrders > 0 && (
                                    <div className="badge bg-primary rounded-pill ms-auto">
                                      {this.state.featureOrders}
                                    </div>
                                  )}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </aside>
    );
  }
}

export default Header;
