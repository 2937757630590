import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import { apiUrl, adminlimit, clientheaderconfig } from "../../Helpers/Config";
import { GET_LISTDATA } from "../../../actions";
import {
  showStatus,
  encodeValue,
  removeItem,
  showLoader,
  hideLoader,
  clientID,
  showAlert,
  checkingPermisson,
} from "../../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";

var module = "clientpanel/products/";
var moduleName = "Products";
class List extends Component {
  constructor(props) {
    super(props);
    var companyID = cookie.load("companyID");
    this.state = {
      companyID: companyID,
      path: this.props.match.path,
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      listdata: [],
      loading: true,
      name: "",
      status: "",
      storeID: "",
      categoryID: "",
      subcategoryID: "",
      outletList: [],
      categoryList: [],
      subcategoryList: [],
      errorFile: false,
      errorComobFile: false,
      allowOutlet:
        cookie.load("clientAllowOutlet") !== "" &&
        typeof cookie.load("clientAllowOutlet") !== "undefined" &&
        typeof cookie.load("clientAllowOutlet") !== undefined
          ? cookie.load("clientAllowOutlet")
          : "",
      exportUrl: "",
      modalImport: false,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.loadList(1);
    this.loadOutlet();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listdata !== this.state.listdata) {
      this.setState({
        listdata: nextProps.listdata,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }
  loadOutlet() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/outlets/dropdownlist?company_id=" +
      this.state.companyID;
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ outletList: res.data.result });
      }
    });
  }
  loadCategory() {
    var storeID =
      this.state.storeID !== null && Object.keys(this.state.storeID).length > 0
        ? this.state.storeID.value
        : "";
    if (storeID !== "") {
      var urlShringTxt =
        apiUrl +
        "clientpanel/category/dropdownlist?company_id=" +
        this.state.companyID +
        "&storeID=" +
        storeID;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        var categoryList = [];
        if (res.data.status === "ok") {
          var result = res.data.result;
          result.map((item) => {
            categoryList.push({
              value: item.pro_cate_id,
              label: item.pro_cate_name,
            });
          });
        }
        this.setState({ categoryList: categoryList });
      });
    } else {
      this.setState({ categoryList: [] });
    }
  }
  loadsubcategory() {
    if (
      this.state.categoryID !== null &&
      Object.keys(this.state.categoryID).length > 0
    ) {
      var urlShringTxt =
        apiUrl +
        "clientpanel/subcategory/subcateonly?company_id=" +
        this.state.companyID +
        "&categoryID=" +
        this.state.categoryID.value;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ subcategoryList: res.data.result });
        }
      });
    } else {
      this.setState({ subcategoryList: [] });
    }
  }

  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  removeItem(deleteID) {
    var params = { delete_id: deleteID, company_id: this.state.companyID };
    var delurl = module + "delete";
    removeItem(
      params,
      delurl,
      "client",
      this.sateValChange,
      this.state.currentPage
    );
  }
  handleChangeText(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }
  handleSelectChange(name, value) {
    this.setState({ [name]: value }, function () {
      if (name === "storeID") {
        this.loadCategory();
      } else if (name === "categoryID") {
        this.loadsubcategory();
      }
    });
  }

  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  loadList(offset) {
    var addParams = "";
    if (this.state.name !== "") {
      addParams += "&name=" + this.state.name;
    }
    if (
      this.state.storeID !== null &&
      Object.keys(this.state.storeID).length > 0
    ) {
      addParams += "&storeID=" + this.state.storeID.value;
    }
    if (
      this.state.categoryID !== null &&
      Object.keys(this.state.categoryID).length > 0
    ) {
      addParams += "&categoryID=" + this.state.categoryID.value;
    }
    if (
      this.state.subcategoryID !== null &&
      Object.keys(this.state.subcategoryID).length > 0
    ) {
      addParams += "&subcategoryID=" + this.state.subcategoryID.value;
    }
    if (
      this.state.status !== null &&
      Object.keys(this.state.status).length > 0
    ) {
      addParams += "&status=" + this.state.status.value;
    }

    var params = {
      params:
        "limit=" +
        adminlimit +
        "&offset=" +
        offset +
        "&company_id=" +
        this.state.companyID +
        addParams +
        "&logID=" +
        cookie.load("clientID"),
      url: apiUrl + module + "list",
      authType: "client",
    };
    var exportUrl =
      apiUrl + module + "export?company_id=" + this.state.companyID + addParams;
    this.setState({ exportUrl: exportUrl });
    this.props.getListData(params);
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        name: "",
        storeID: "",
        categoryID: "",
        subcategoryID: "",
        status: "",
      },
      function () {
        this.loadList(1);
      }
    );
  }
  importProduct() {
    this.setState({
      modalImport: true,
    });
    $("#importpopup")
      .click(function () {
        this.click();
      })
      .click();
    // $("#productmodal").modal("toggle");
  }

  confirmimportProducts(type) {
    var formData = new FormData();
    var currentThis = this;
    imagefile = "";
    if (type === "Simple Product") {
      var imagefile = document.querySelector("#simple_file");
    } else if (type === "Combo Product") {
      var imagefile = document.querySelector("#combo_file");
    }
    if (imagefile !== "") {
      if (imagefile.files.length === 0) {
        if (type === "Simple Product") {
          this.setState({ errorFile: true });
        } else if (type === "Combo Product") {
          this.setState({ errorComobFile: true });
        }

        return false;
      } else {
        if (type === "Simple Product") {
          this.setState({ errorFile: false });
        } else if (type === "Combo Product") {
          this.setState({ errorComobFile: false });
        }
      }

      if (type === "Simple Product") {
        showLoader("import_simple");
      } else if (type === "Combo Product") {
        showLoader("import_combo");
      }
      formData.append("import_file", imagefile.files[0]);
      formData.append("company_admin_id", clientID());
      formData.append("company_id", this.state.companyID);
      formData.append("type", type);
      axios
        .post(apiUrl + module + "importsimple", formData, {
          headers: {
            Authorization: cookie.load("clientAccessToken"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          if (type === "Simple Product") {
            hideLoader("import_simple");
          } else if (type === "Combo Product") {
            hideLoader("import_combo");
          }
          if (type === "Simple Product") {
            $("#simple_file").val("");
          } else if (type === "Combo Product") {
            $("#combo_file").val("");
          }
          var errMsg =
            res.data.form_error !== "" ? res.data.form_error : res.data.message;
          if (res.data.status === "success") {
            currentThis.setState({ loading: true }, function () {
              currentThis.loadList(1);
            });
            showAlert("Success", errMsg, "success", "No");
            $(document).ready(function () {
              // Aktifkan modal ketika elemen dengan ID "importpopup" diklik
              $("#importpopup").click(function () {
                $("#productmodal").modal("toggle");
              });
            });
          } else {
            showAlert("Error", errMsg, "error", "No");
          }
        });
    }
  }
  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"catalog-products"} />
          <div className="layout-page">
            <Topmenu />

            <a
              href={void 0}
              className="hidden"
              data-bs-toggle="modal"
              data-bs-target="#productmodal"
              id="importpopup"
            >
              Load
            </a>

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                  {checkingPermisson(["catalogs"], "add") && (
                    <div className="col-lg-6 col-md-6 text-end">
                      <Link to={this.state.path + "add"}>
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                        >
                          Add New
                        </button>
                      </Link>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                        onClick={this.importProduct.bind(this)}
                      >
                        Import
                      </button>
                      &nbsp;
                      <a href={this.state.exportUrl}>
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                        >
                          Export
                        </button>
                      </a>
                    </div>
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={this.handleChangeText}
                        value={this.state.name}
                      />
                      <label htmlFor="name">Search Keywords</label>
                    </div>
                  </div>
                  {this.state.allowOutlet === "" && (
                    <div className="col-md-3">
                      <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                        <Select
                          value={this.state.storeID}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "storeID"
                          )}
                          placeholder="Select Outlet"
                          isClearable={true}
                          options={this.state.outletList}
                        />
                        <label className="select-box-label">Outlet</label>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      this.state.allowOutlet !== "" ? "col-md-4" : "col-md-3"
                    }
                  >
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.categoryID}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "categoryID"
                        )}
                        placeholder="Select Category"
                        isClearable={true}
                        options={this.state.categoryList}
                      />
                      <label className="select-box-label">Categoryss</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.subcategoryID}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "subcategoryID"
                        )}
                        placeholder="Select Sub Category"
                        isClearable={true}
                        options={this.state.subcategoryList}
                      />
                      <label className="select-box-label">Sub Category</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.status}
                        onChange={this.handleSelectChange.bind(this, "status")}
                        placeholder="Select Status"
                        isClearable={true}
                        options={[
                          { value: "A", label: "Active" },
                          { value: "I", label: "In Active" },
                        ]}
                      />
                      <label className="select-box-label">Status</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={this.searchList.bind(this)}
                    >
                      Search
                    </button>
                    <button
                      type="reset"
                      className="btn btn-label-secondary waves-effect"
                      onClick={this.resetSearch.bind(this)}
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Outlet Name</th>
                          <th>Type Of Product</th>
                          <th>Category</th>
                          <th>Sub Category</th>
                          <th>SKU</th>
                          <th>Price</th>
                          <th>Sequence</th>
                          <th>Status</th>
                          {(checkingPermisson(["catalogs"], "edit") ||
                            checkingPermisson(["catalogs"], "delete")) && (
                            <th>Actions</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={7} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.listdata.length > 0 ? (
                          this.state.listdata.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <strong>{item.product_name}</strong>
                                </td>
                                <td>{item.outlet_name}</td>
                                <td>{item.product_type_name}</td>
                                <td>{item.pro_cate_name}</td>
                                <td>{item.pro_subcate_name}</td>
                                <td>{item.product_sku}</td>
                                <td>{item.product_price}</td>
                                <td className="text-center">
                                  {item.product_sequence}
                                </td>
                                <td>{showStatus(item.product_status)}</td>
                                {(checkingPermisson(["catalogs"], "edit") ||
                                  checkingPermisson(
                                    ["catalogs"],
                                    "delete"
                                  )) && (
                                  <td>
                                    <div className="dropdown">
                                      <button
                                        type="button"
                                        className="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                      >
                                        <i className="mdi mdi-dots-horizontal"></i>
                                      </button>
                                      <div className="dropdown-menu">
                                        {checkingPermisson(
                                          ["catalogs"],
                                          "edit"
                                        ) && (
                                          <Link
                                            to={
                                              this.state.path +
                                              "edit/" +
                                              encodeValue(
                                                item.product_primary_id
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            <i className="mdi mdi-pencil-outline me-1"></i>
                                            Edit
                                          </Link>
                                        )}
                                        {checkingPermisson(
                                          ["catalogs"],
                                          "delete"
                                        ) && (
                                          <a
                                            className="dropdown-item"
                                            href={void 0}
                                            onClick={this.removeItem.bind(
                                              this,
                                              encodeValue(
                                                item.product_primary_id
                                              )
                                            )}
                                          >
                                            <i className="mdi mdi-trash-can-outline me-1"></i>
                                            Delete
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No {moduleName} Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <div
          className="modal fade"
          id="productmodal"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Import Products</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className=" mb-4">
                  <div className="card-body mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Import Simple Product
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="simple_file"
                              name="simple_file"
                            />
                            {this.state.errorFile === true && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                          <a
                            href="/files/simple-product.csv"
                            download="simple-product.csv"
                          >
                            Download Simple Products Sample File
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Import Combo Product
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="combo_file"
                              name="combo_file"
                            />
                            {this.state.errorComobFile === true && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                          <a
                            href="/files/combo-product.csv"
                            download="combo-product.csv"
                          >
                            Download Combo Products Sample File
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="import_simple"
                  onClick={this.confirmimportProducts.bind(
                    this,
                    "Simple Product"
                  )}
                >
                  Import Simple Product
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="import_combo"
                  onClick={this.confirmimportProducts.bind(
                    this,
                    "Combo Product"
                  )}
                >
                  Import Combo Product
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var listdata = Array();
  var totalPages = 0;
  var totalRecords = 0;
  if (Object.keys(state.listdata).length > 0) {
    if (state.listdata[0].status === "ok") {
      listdata = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
    }
  }
  return {
    listdata: listdata,
    totalPages: totalPages,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
