/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { CompanyID, showPriceValue } from "../../Helpers/SettingHelper";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import { connect } from "react-redux";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import moment from "moment";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Outlet",
    selector: (row) => row.outlet,
    sortable: true,
  },
  {
    name: "Revenue",
    selector: (row) => row.revenue,
    sortable: true,
  },
  {
    name: "Total Orders",
    selector: (row) => row.totalorder,
    sortable: true,
  },
  {
    name: "Average",
    selector: (row) => row.average,
    sortable: true,
  },
];
const itemcolumns = [
  {
    name: "Item",
    selector: (row) => row.item,
    sortable: true,
  },
  {
    name: "Revenue",
    selector: (row) => row.revenue,
    sortable: true,
  },
  {
    name: "Total Orders",
    selector: (row) => row.totalorder,
    sortable: true,
  },
  {
    name: "Average",
    selector: (row) => row.average,
    sortable: true,
  },
];

const SiteLocationcolumns = [
  {
    name: "Location Name",
    selector: (row) => row.item,
    sortable: true,
  },
  {
    name: "Revenue",
    selector: (row) => row.revenue,
    sortable: true,
  },
  {
    name: "Total Orders",
    selector: (row) => row.totalorder,
    sortable: true,
  },
  {
    name: "Average",
    selector: (row) => row.average,
    sortable: true,
  },
];

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterType: "7days",
      start_date: "",
      end_date: "",
      error_start_date: false,
      error_end_date: false,
      totalAmount: 0,
      todaytotalAmount: 0,
      totalCustomer: 0,
      totalCart: 0,
      series: [],
      initialData: true,
      options: {
        noData: {
          text: "Loading...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Helvetica",
          },
        },
        bar: {
          columnWidth: "50",
        },
      },
      optionsbar: {},
      seriesbar: [],
      outletrevenue: [],
      itemrevenue: [],
      sitelocationrevenue: [],
    };
  }
  componentDidMount() {
    this.loadData("7days");
  }
  setCustom() {
    this.setState({ filterType: "custom" });
  }

  loadData(filterType) {
    this.setState(
      {
        filterType: filterType,
        options: {
          noData: {
            text: "Loading...",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica",
            },
          },
          series: [
            {
              name: "Salse",
              data: [],
            },
          ],
        },
      },
      function () {
        var xaxisTitle = "";
        var dateFilter = "";
        if (filterType === "7days") {
          xaxisTitle = "Days";
        } else if (filterType === "custom") {
          var error = 0;
          if (this.state.start_date === "") {
            error++;
            this.setState({ error_start_date: true });
          } else {
            this.setState({ error_start_date: false });
          }
          if (this.state.end_date === "") {
            error++;
            this.setState({ error_end_date: true });
          } else {
            this.setState({ error_end_date: false });
          }
          if (error > 0) {
            return false;
          }

          dateFilter =
            "&start_date=" +
            moment(this.state.start_date).format("Y-MM-DD") +
            "&end_date=" +
            moment(this.state.end_date).format("Y-MM-DD");
        }

        var urlShringTxt =
          apiUrl +
          "clientpanel/dashboard?company_id=" +
          CompanyID() +
          "&logID=" +
          cookie.load("clientID") +
          "&filterType=" +
          filterType +
          dateFilter +
          "&initialData=" +
          this.state.initialData;
        axios.get(urlShringTxt, clientheaderconfig).then((res) => {
          var curretnThis = this;

          if (res.data.status === "ok") {
            var xaxis = res.data.result.xaxis;
            var yaxisdata = res.data.result.datas;
            var itemxaxis = res.data.result.itemxaxis;
            var itemdata = res.data.result.itemdata;
            var outletRevenue = res.data.result.outletRevenue;
            var itemRevenue = res.data.result.itemRevenue;
            var siteLocRevenue = res.data.result.siteLocRevenue;

            if (this.state.initialData === true) {
              this.setState({
                totalAmount: res.data.result.totalAmount,
                todaytotalAmount: res.data.result.todaytotalAmount,
                totalCustomer: res.data.result.totalCustomer,
                totalCart: res.data.result.totalCart,
              });
            }
            var updateItemData = [];
            if (itemdata.length > 0) {
              itemdata.map((item, index) => {
                updateItemData.push({ x: itemxaxis[index], y: item });
              });
            }

            var outlet_revenue = [];
            if (outletRevenue.length > 0) {
              outletRevenue.map((item) => {
                outlet_revenue.push({
                  outlet: item.outlet_name,
                  revenue:
                    item.totalAmount !== null
                      ? showPriceValue(item.totalAmount)
                      : showPriceValue("0.000"),
                  totalorder: item.totalOrder,
                  average:
                    parseInt(item.totalOrder) > 0
                      ? showPriceValue(item.totalAmount / item.totalOrder)
                      : showPriceValue("0.000"),
                });
              });
            }

            var item_revenue = [];
            if (itemRevenue.length > 0) {
              itemRevenue.map((item) => {
                item_revenue.push({
                  item: item.item_name,
                  revenue:
                    item.totalAmount !== null
                      ? showPriceValue(item.totalAmount)
                      : showPriceValue("0.000"),
                  totalorder: item.totalItem,
                  average:
                    parseInt(item.totalItem) > 0
                      ? showPriceValue(item.totalAmount / item.totalItem)
                      : showPriceValue("0.000"),
                });
              });
            }

            var siteLoc_revenue = [];
            if (siteLocRevenue.length > 0) {
              siteLocRevenue.map((item) => {
                siteLoc_revenue.push({
                  item: item.siteLocation,
                  revenue:
                    item.totalAmount !== null
                      ? showPriceValue(item.totalAmount)
                      : showPriceValue("0.000"),
                  totalorder: item.totalOrders,
                  average:
                    parseInt(item.totalOrders) > 0
                      ? showPriceValue(item.totalAmount / item.totalOrders)
                      : showPriceValue("0.000"),
                });
              });
            }

            this.setState({
              initialData: false,
              outletrevenue: outlet_revenue,
              itemrevenue: item_revenue,
              sitelocationrevenue: siteLoc_revenue,
              series: [
                {
                  name: "Salse",
                  data: yaxisdata,
                },
              ],
              options: {
                chart: {
                  height: 450,
                  type: "line",
                  dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                colors: ["#77B6EA", "#545454"],
                dataLabels: {
                  enabled: true,
                },
                stroke: {
                  curve: "smooth",
                },
                title: {
                  text: "Last 7 days orders",
                  align: "left",
                },
                grid: {
                  borderColor: "#e7e7e7",
                  row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5,
                  },
                },
                markers: {
                  size: 1,
                },
                xaxis: {
                  categories: xaxis,
                  title: {
                    text: xaxisTitle,
                  },
                },
                yaxis: {
                  title: {
                    text: "Total Amount",
                  },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
              optionsbar: {
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  categories: itemxaxis,
                },
                bar: {
                  columnWidth: 10,
                },
              },
              seriesbar: [
                {
                  name: "Salse",
                  data: updateItemData,
                },
              ],
            });
          } else {
          }
        });
      }
    );
  }

  handleChangeDate(name, value) {
    var required = false;
    if (value === "") {
      required = true;
    }

    this.setState({ [name]: value, ["error_" + name]: required });
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"dashboard"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold">Dashboard</h4>

                <div className="col-lg-12">
                  <div className="card h-100">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h4 className="mb-2">Sales Overview</h4>
                      </div>
                    </div>
                    <div className="card-body d-flex justify-content-between flex-wrap gap-3">
                      <div className="d-flex gap-3">
                        <div className="avatar">
                          <div className="avatar-initial bg-label-warning rounded">
                            <i className="mdi mdi-poll mdi-24px"></i>
                          </div>
                        </div>
                        <div className="card-info">
                          <h4 className="mb-0">{this.state.totalAmount}</h4>
                          <small className="text-muted">Total Salse</small>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="avatar">
                          <div className="avatar-initial bg-label-warning rounded">
                            <i className="mdi mdi-poll mdi-24px"></i>
                          </div>
                        </div>
                        <div className="card-info">
                          <h4 className="mb-0">{this.state.todaytotalAmount}</h4>
                          <small className="text-muted">Today Salse</small>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="avatar">
                          <div className="avatar-initial bg-label-primary rounded">
                            <i className="mdi mdi-account-outline mdi-24px"></i>
                          </div>
                        </div>
                        <div className="card-info">
                          <h4 className="mb-0">{this.state.totalCustomer}</h4>
                          <small className="text-muted">Total Customer</small>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="avatar">
                          <div className="avatar-initial bg-label-info rounded">
                            <i className="mdi mdi-trending-up mdi-24px"></i>
                          </div>
                        </div>

                        <div className="card-info">
                          <h4 className="mb-0">{this.state.totalCart}</h4>
                          <small className="text-muted">Abandon Carts </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <div className="demo-inline-spacing">
                    <button
                      type="button"
                      onClick={this.loadData.bind(this, "7days")}
                      className={
                        this.state.filterType === "7days"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                    >
                      7 Days
                    </button>
                    <button
                      onClick={this.loadData.bind(this, "1month")}
                      type="button"
                      className={
                        this.state.filterType === "1month"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                    >
                      1 Month
                    </button>
                    <button
                      type="button"
                      onClick={this.loadData.bind(this, "3month")}
                      className={
                        this.state.filterType === "3month"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                    >
                      3 Month
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.filterType === "6month"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                      onClick={this.loadData.bind(this, "6month")}
                    >
                      6 Month
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.filterType === "1year"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                      onClick={this.loadData.bind(this, "1year")}
                    >
                      1 Year
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.filterType === "custom"
                          ? "btn btn-outline-primary waves-effect active"
                          : "btn btn-outline-primary waves-effect"
                      }
                      onClick={this.setCustom.bind(this)}
                    >
                      Custom
                    </button>
                    {this.state.filterType === "custom" && (
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <div className="form-floating form-floating-outline mb-4">
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              selected={this.state.start_date}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Start Date"
                              onChange={this.handleChangeDate.bind(
                                this,
                                "start_date"
                              )}
                            />
                            {this.state.error_start_date && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating form-floating-outline mb-4">
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              selected={this.state.end_date}
                              minDate={
                                this.state.start_date !== ""
                                  ? this.state.start_date
                                  : ""
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="End Date"
                              onChange={this.handleChangeDate.bind(
                                this,
                                "end_date"
                              )}
                            />
                            {this.state.error_end_date && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-1 mt-2">
                          <button
                            type="button"
                            className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                            onClick={this.loadData.bind(this, "custom")}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card mt-2">
                    <div className="card-body gap-3">
                      <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type="line"
                        height={450}
                      />
                    </div>
                  </div>
                  <div className="card mt-4">
                    {/* Most item Salse */}
                    <div className="card-body gap-3">
                      <ReactApexChart
                        options={this.state.optionsbar}
                        series={this.state.seriesbar}
                        type="bar"
                        height={450}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4 mt-4">
                  <div className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-1">Outlet Wise Revenue</h5>
                        </div>
                      </div>
                      <div className="card-body pt-4 pb-2 mt-2">
                        <DataTable
                          columns={columns}
                          data={this.state.outletrevenue}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12">
                    <div className="card h-100">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title m-0">
                          <h5 className="mb-0">Item Revenue</h5>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <DataTable
                          columns={itemcolumns}
                          data={this.state.itemrevenue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <div className="card h-100">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title m-0">
                          <h5 className="mb-0">Site Location Revenue</h5>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <DataTable
                          columns={SiteLocationcolumns}
                          data={this.state.sitelocationrevenue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
